








import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { CmsPage } from '~/modules/GraphQL/types';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent } from '~/composables';
import PageBuilder from "~/modules/gomage/plugin-page-builder/components/Pagebuilder.vue";

export default defineComponent({
  name: 'HomePage',
  components: {
    PageBuilder,
  },

  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();

    const page = ref<CmsPage | null>(null);


    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    // @ts-ignore
    return {
      page,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
